import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { Notification } from '../../interfaces/notification.interface';
import { NotificationType } from '../../enums/notification-type.enum';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
  private notificationSub$: Subscription = new Subscription(); // Subscription to handle notification updates
  activeNotification: Notification | null = null; // Holds the currently active notification

  constructor(private notificationService: NotificationService) { }

  ngOnInit(): void {
    this.notificationSub$ = this.notificationService.getActiveNotification
      .subscribe(notification => {
        this.activeNotification = notification; // Update the activeNotification with new data
      });
  }

  /**
   * Computes the CSS class to apply based on the notification type.
   * @returns A string representing the CSS class for the notification type
   */
  get getNotificationClass(): string {
    if (!this.activeNotification) return ''; // Return empty string if no active notification

    switch (this.activeNotification.type) {
      case NotificationType.Success:
        return 'success'; // Class for success notifications
      case NotificationType.Error:
        return 'error'; // Class for error notifications
      case NotificationType.Warning:
        return 'warning'; // Class for warning notifications
      default:
        return ''; // Default case if the notification type is unknown
    }
  }

  ngOnDestroy(): void {
    this.notificationSub$.unsubscribe();
  }
}
