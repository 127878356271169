import { Directive, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appOnlyNumbers]',
})
export class OnlyNumbersDirective {
  constructor(private renderer: Renderer2) { }

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const sanitizedValue = input.value.replace(/[^0-9]/g, '');
    this.renderer.setProperty(input, 'value', sanitizedValue);
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    const pastedData = clipboardData.getData('text');
    const sanitizedPastedData = pastedData.replace(/[^0-9]/g, '');
    event.clipboardData?.setData('text', sanitizedPastedData);
    event.preventDefault();
    const input = event.target as HTMLInputElement;
    this.renderer.setProperty(input, 'value', sanitizedPastedData);
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent): void {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }
}