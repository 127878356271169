import { Injectable } from '@angular/core';
import { ColorType } from 'highcharts';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  downloadFile(data: any, fileName: string): void {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(anchor);
  }

  colorPalette: ColorType[] =
    [
      "#1ABC9C",
      "#779ECB", // Pastel Blue
      "#9A4EAE", // Muted Purple
      "#C71585", // Dark Pastel Pink
      "#836FFF", // Muted Violet
      "#CD5B45", // Dark Pastel Coral
      "#66CDAA", // Pastel Teal
      "#FFB347", // Pastel Orange
      "#C23B22", // Dark Pastel Red
      "#77DD77", // Pastel Green
      "#FFD700", // Dark Pastel Yellow
      "#800000",  // Dark Pastel Maroon
      "#8FBC8F", // Pastel Olive
    ];
  commonTextStyle = {
    fontSize: '12px',
    fontFamily: 'Verdana, sans-serif'
  };

  commonLabelStyle = {
    fontSize: '10px',
    fontFamily: 'Verdana, sans-serif'
  };

  getCurrentAndOneMonthAgoDate() {
    const today = new Date();
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(today.getMonth() - 1);

    return {
      currentDate: this.formatDate(today),
      oneMonthAgo: this.formatDate(oneMonthAgo)
    };
  }
  private formatDate(date:any): string {
    return formatDate(date || new Date(), 'yyyy-MM-dd', 'en-US');
  }
}
