import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'serialNumber'
})
export class SerialNumberPipe implements PipeTransform {

  /**
   * Generates a serial number using the formula:
   *
   * - (pageNumber * pageSize) + index
   * - Returns the result as a zero-padded string if it is less than 10.
   * - Returns the result as a string without additional padding if it is 10 or greater.
   *
   * @param index - The current index or value.
   * @param pageNumber - The current page number.
   * @param pageSize - The size of each page.
   * @returns A zero-padded string representing the serial number.
   */
  transform(index: number | string, pageNumber: number, pageSize: number): string {
    const numberValue = typeof index === 'number' ? index : parseInt(index, 10);
    const serialNumber = (pageNumber * pageSize) + numberValue + 1;
    return serialNumber < 10 ? `0${serialNumber}` : `${serialNumber}`;
  }
}