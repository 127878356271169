import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-dashboard-layout',
  templateUrl: './main-dashboard-layout.component.html',
  styleUrls: ['./main-dashboard-layout.component.scss']
})
export class MainDashboardLayoutComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }

}