import { Directive, ElementRef, HostListener, Input } from "@angular/core";
@Directive({
    selector: '[appDecimal]',
})
export class DecimalDirective {

    @Input() decimalDigits!: number;

    navigationKeys: Array<string> = [
        "delete",
        "backspace",
        "tab",
        "escape",
        "enter",
        ".",
        "arrowleft",
        "arrowright"
    ];

    constructor(private eleRef: ElementRef) { }

    @HostListener('keydown', ['$event']) onKeyDown(e: KeyboardEvent) {
        let key = e.key?.toLowerCase() || ""
        if (
            this.navigationKeys.indexOf(key) > -1 ||
            (key === 'a' && e.ctrlKey === true) || 
            (key === 'c' && e.ctrlKey === true) ||
            (key === 'v' && e.ctrlKey === true) || 
            (key === 'x' && e.ctrlKey === true) || 
            (key === 'a' && e.metaKey === true) || 
            (key === 'c' && e.metaKey === true) || 
            (key === 'v' && e.metaKey === true) || 
            (key === 'x' && e.metaKey === true) 
        ) {
            return; 
        }
        if (e.key === ' ' || isNaN(Number(e.key))) {
            e.preventDefault();
        }
        if (this.eleRef.nativeElement && this.eleRef.nativeElement?.value) {
            let pattern = `^[0-9]+(\\.[0-9]{0,${(this.decimalDigits || 0) - 1}})?$`;
            const regex: RegExp = new RegExp(pattern);
            let value: string = this.eleRef.nativeElement.value;
            if (value && !String(value).match(regex)) {
                e.preventDefault();
            }
        }
    }

    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
        event.preventDefault();
        if (event?.clipboardData) {
            let pastedInput: string = event.clipboardData
                .getData('text/plain')
                .replace(/[^0-9.]/g, '');
            pastedInput = parseFloat(pastedInput)?.toString();
            if (pastedInput) {
                document.execCommand('insertText', false, pastedInput);
            }
        }
    }
}