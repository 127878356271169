import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { Notification } from 'src/app/shared/interfaces/notification.interface';
import { NotificationType } from 'src/app/shared/enums/notification-type.enum';
import { NOTIFICATION_DISPLAY_DURATION } from 'src/app/core/constants/app.constant';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private activeNotification$ = new BehaviorSubject<Notification | null>(null); // The active notification

  constructor() { }

  /**
   * Returns an observable that emits the active notification.
   * @returns An observable that emits the active notification.
   */
  get getActiveNotification(): Observable<Notification | null> {
    return this.activeNotification$.asObservable();
  }

  /**
   * Shows a success notification with the specified message.
   * @param message The message to be displayed in the success notification.
   */
  showSuccess(message: string): void {
    this.showNotification(NotificationType.Success, message);
  }

  /**
   * Shows an error notification with the specified message.
   * @param message The message to be displayed in the error notification.
   */
  showError(message: string): void {
    this.showNotification(NotificationType.Error, message);
  }

  /**
   * Shows a warning notification with the specified message.
   * @param message The message to be displayed in the warning notification.
   */
  showWarning(message: string): void {
    this.showNotification(NotificationType.Warning, message);
  }

  /**
   * Shows a notification with the specified type and message.
   * @param type The type of the notification.
   * @param message The message to be displayed in the notification.
   */
  private showNotification(type: NotificationType, message: string): void {
    const notification: Notification = { id: Date.now(), type, message };
    this.activeNotification$.next(notification);

    timer(NOTIFICATION_DISPLAY_DURATION)
      .pipe(take(1))
      .subscribe(() => {
        this.activeNotification$.next(null);
      });
  }
}