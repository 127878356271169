import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogData } from '../../interfaces/confirmation-dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  title: string = 'Confirmation'; // Default title of the dialog
  message: string = 'Are you sure you want to proceed?'; // Default message displayed in the dialog

  /**
   * Creates an instance of the ConfirmationDialogComponent.
   * @param matDialogRef Reference to the MatDialog for controlling the dialog's behavior
   * @param data Data passed to the dialog, including title and message
   */
  constructor(
    public matDialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
  ) {
    // Initialize title and message with values from the dialog data
    this.title = data.title;
    this.message = data.message;
  }

  ngOnInit(): void { }

  /**
   * Closes the dialog with a response of false (cancellation).
   */
  onClose() {
    this.matDialogRef.close(false);
  }

  /**
   * Closes the dialog with a response of false (cancellation).
   */
  onCancel() {
    this.matDialogRef.close(false);
  }

  /**
   * Closes the dialog with a response of true (confirmation).
   */
  onConfirm() {
    this.matDialogRef.close(true);
  }
}