import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { MESSAGE } from '../constants/message.constants';
import { APP_ROUTES } from '../constants/app-routes.constants';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private httpClient: HttpClient) { }

  /**
   * Handles the error response from the API.
   * @param error - The HttpErrorResponse object.
   * @returns An Observable that emits an error.
   */
  private handleError(error: any): Observable<never> {
    let notificationService = new NotificationService();
    if (error.statusCode == 401) {
      localStorage.removeItem('token');
      notificationService.showError(MESSAGE.ERROR.SESSION_EXPIRED);
    } else if (error.status === 300) {
      window.location.href = '.' + APP_ROUTES.AUTH.SELECT_USER;
    } else {
      notificationService.showError(MESSAGE.ERROR.SOMETHING_WENT_WRONG);
    }
    return throwError(() => error);
  }

  private handleSuccess(result: any) {
    return result;
  }

  /**
   * Creates the HttpHeaders object with the necessary headers.
   * @returns The HttpHeaders object.
   */
  private createHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  /**
   * Sends a GET request to the specified URL.
   * @param url - The URL to send the request to.
   * @returns An Observable that emits the response data.
   */
  get<T>(url: string, res?: string): Observable<T> {
    let options = {
      headers: this.createHeaders(),
      responseType: res as any
    }
    return this.httpClient.get<T>(url, options)
      .pipe(catchError(this.handleError))
  }

  /**
   * Sends a POST request to the specified URL with the given body.
   * @param url - The URL to send the request to.
   * @param body - The request body.
   * @returns An Observable that emits the response data.
   */
  post<T>(url: string, body: any, responseType?: string): Observable<T> {
    let options = {
      headers: this.createHeaders(),
      responseType: responseType as any
    }
    return this.httpClient.post<T>(url, body, options)
      .pipe(catchError(this.handleError));
  }

  /**
   * Sends a PUT request to the specified URL with the given body.
   * @param url - The URL to send the request to.
   * @param body - The request body.
   * @returns An Observable that emits the response data.
   */
  put<T>(url: string, body: any): Observable<T> {
    return this.httpClient.put<T>(url, body, { headers: this.createHeaders() })
      .pipe(this.handleSuccess, catchError(this.handleError));
  }

  /**
   * Sends a DELETE request to the specified URL.
   * @param url - The URL to send the request to.
   * @returns An Observable that emits the response data.
   */
  delete<T>(url: string): Observable<T> {
    return this.httpClient.delete<T>(url, { headers: this.createHeaders() })
      .pipe(this.handleSuccess, catchError(this.handleError));
  }
}