import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "./material/material.module";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NotificationComponent } from "./components/notification/notification.component";
import { DateRangePickerListComponent } from "./components/date-range-picker-list/date-range-picker-list.component";
import { DatePickerCustomHeaderComponent } from "./components/date-range-picker-list/date-picker-custom-header.component";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { NoDataFoundComponent } from "./components/no-data-found/no-data-found.component";
import { DecimalDirective } from "./directives/decimal.directive";
import { OnlyNumbersDirective } from "./directives/only-numbers.directive";
import { SentenceCasePipe } from "./pipes/sentence-case.pipe";
import { SerialNumberPipe } from "./pipes/serial-number.pipe";
import { CustomerStatusPipe } from "./pipes/customer-status.pipe";
import { ColumnChartComponent } from "./charts/columnCharts.component";
import { PieChartComponent } from "./charts/pieCharts.component";
import { IndianNumberSystemPipe } from "./pipes/indian-number-system.pipe";
import { IndianNumberSystemDirective } from "./directives/indian-number-system.directive";

@NgModule({
  declarations: [
    NotificationComponent,
    DateRangePickerListComponent,
    DatePickerCustomHeaderComponent,
    ConfirmationDialogComponent,
    BreadcrumbComponent,
    PaginationComponent,
    NoDataFoundComponent,
    DecimalDirective,
    OnlyNumbersDirective,
    SentenceCasePipe,
    SerialNumberPipe,
    CustomerStatusPipe,
    ColumnChartComponent,
    PieChartComponent,
    IndianNumberSystemPipe,
    IndianNumberSystemDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
    MatTableModule,
    MatTooltipModule
  ],
  exports: [
    NotificationComponent,
    DateRangePickerListComponent,
    DatePickerCustomHeaderComponent,
    ConfirmationDialogComponent,
    BreadcrumbComponent,
    PaginationComponent,
    NoDataFoundComponent,
    DecimalDirective,
    OnlyNumbersDirective,
    SentenceCasePipe,
    SerialNumberPipe,
    CustomerStatusPipe,
    ColumnChartComponent,
    PieChartComponent,
    IndianNumberSystemPipe,
    IndianNumberSystemDirective
  ]
})
export class SharedModule { }