import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { finalize, Observable } from 'rxjs';
import { LocalStorageService } from '../services/local-storage.service';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private localStorageService: LocalStorageService,
    private loader: LoaderService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loader.show(); // Display loading spinner

    let modifiedRequest = request;
    const userDetails = this.localStorageService.getItem('userDetails');
    const token = this.localStorageService.getItem('token');
    const eid = userDetails && userDetails.eid ? userDetails.eid : '';
    const userId = userDetails && userDetails.userId ? JSON.stringify(userDetails.userId) : '';
    const userTypeId = userDetails && userDetails.userTypeId ? JSON.stringify(userDetails.userTypeId) : '';

    if (token) {
      modifiedRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          eid: eid,
          usertypeid: userTypeId,
          userId: userId,
        }
      });
    }

    // Handle the modified request and hide the loading spinner upon completion
    return next.handle(modifiedRequest).pipe(
      finalize(() => this.loader.hide())
    );
  }
}