export const APP_ROUTES = {
  AUTH: {
    LOGIN: '/auth/login',
    SELECT_USER: '/auth/select-user-type'
  },
  UNAUTHORIZED: '/unauthorized',
  SALES: '/sales',
  SUMMARY: '/summary',
  PERFORMANCE: '/performance',
  CUSTOMER: {
    LIST: '/customers',
    SUSPENSION: '/customers/suspension',
    REVOKE_SUSPENSION: '/customers/suspension/revoke',
    DELIVERY_CHALLAN: '/customers/delivery-challan',
  },
};