import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loadingState = new Subject<boolean>();

  constructor() { }

  /**
   * Emits a value of true to indicate that a loading process has started.
   */
  show(): void {
    this.loadingState.next(true);
  }

  /**
   * Emits a value of false to indicate that the loading process has ended.
   */
  hide(): void {
    this.loadingState.next(false);
  }

  /**
   * Returns an observable that emits the current loading state.
   * Subscribers can use this to react to changes in the loading state.
   */
  getLoadingState(): Observable<boolean> {
    return this.loadingState.asObservable();
  }
}