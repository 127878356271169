export const environment = {
  production: false,
  env: 'QA', // Environment name
  microsoft: {
    clientId: "f029693e-6c59-4b75-985d-4620aa7c3b3b", // Microsoft Application (Client) ID used for authentication
    authority: 'https://login.microsoftonline.com/421e9584-87ff-424f-95b8-1bf46b70db99/', // Microsoft Identity Platform Tenant ID
    redirectUri: 'https://erpdm.moglilabs.com/auth/login', // Redirect URI for the application
  },
  microservices: {
    digimroAuthBaseUrl: 'https://digimro-erpauth.moglilabs.com/api/', // Base URL for the Digimro Authentication Service
    accountServiceBaseUrl: 'https://mce-account-service-qa1.moglilabs.com/', // Base URL for the Moglix Account Service
    digimroErpBaseUrl: 'https://digimro-erpapi.moglilabs.com/api/', // Base URL for the Digimro ERP Service
    digimroCoreBaseUrl: 'https://digimro-core.moglilabs.com/api/', // Base URL for the Digimro Core Service
  },
  base: {
    url: 'https://erpdm.moglilabs.com/', // Base URL for the frontend application in the Production environment
  },
};