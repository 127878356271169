import { Component } from '@angular/core';
import { ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatDateRangePicker } from '@angular/material/datepicker';

const customPresets = [
  'Today',
  'Last 7 Days',
  'This Week',
  'This Month',
  'This Year',
  'Last Week',
  'Last Month',
  'Last Year',
] as const; // convert to readonly tuple of string literals

// equivalent to "Today" | "last 7 days" | ... | "Last year"
type CustomPreset = typeof customPresets[number];
@Component({
  selector: 'app-date-range-picker-list',
  templateUrl: './date-range-picker-list.component.html',
  styleUrls: ['./date-range-picker-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangePickerListComponent<D> {
 // list of range presets we want to provide:
 readonly customPresets = customPresets;
 @HostBinding('class') class = 'touch-ui';
//  @HostBinding('touch-ui')
 readonly isTouchUi = this.picker.touchUi;

 constructor(
   private dateAdapter: DateAdapter<D>,
   private picker: MatDateRangePicker<D>
 ) {}

 // called when user selects a range preset:
 selectRange(rangeName: CustomPreset): void {
   const [start, end] = this.calculateDateRange(rangeName);
   this.picker.select(start);
   this.picker.select(end);
   this.picker.close();
 }

 private calculateDateRange(rangeName: CustomPreset): [start: D, end: D] {
   const Today = this.Today;
   const year = this.dateAdapter.getYear(Today);

   switch (rangeName) {
     case 'Today':
       return [Today, Today];
     case 'Last 7 Days': {
       const start = this.dateAdapter.addCalendarDays(Today, -6);
       return [start, Today];
     }
     case 'This Week': {
       return this.calculateWeek(Today);
     }
     case 'This Month': {
       return this.calculateMonth(Today);
     }
     case 'This Year': {
       const start = this.dateAdapter.createDate(year, 0, 1);
       const end = this.dateAdapter.createDate(year, 11, 31);
       return [start, end];
     }
     case 'Last Week': {
       const thisDayLastWeek = this.dateAdapter.addCalendarDays(Today, -7);
       return this.calculateWeek(thisDayLastWeek);
     }
     case 'Last Month': {
       const thisDayLastMonth = this.dateAdapter.addCalendarMonths(Today, -1);
       return this.calculateMonth(thisDayLastMonth);
     }
     case 'Last Year': {
       const start = this.dateAdapter.createDate(year - 1, 0, 1);
       const end = this.dateAdapter.createDate(year - 1, 11, 31);
       return [start, end];
     }
     default:
       // exhaustiveness check;
       // rangeName has type never, if every possible value is handled in the switch cases.
       // Otherwise, the following line will result in compiler error:
       // "Type 'string' is not assignable to type '[start: D, end: D]'"
       return rangeName;
   }
 }

 private calculateMonth(forDay: D): [start: D, end: D] {
   const year = this.dateAdapter.getYear(forDay);
   const month = this.dateAdapter.getMonth(forDay);
   const start = this.dateAdapter.createDate(year, month, 1);
   const end = this.dateAdapter.addCalendarDays(
     start,
     this.dateAdapter.getNumDaysInMonth(forDay) - 1
   );
   return [start, end];
 }

 private calculateWeek(forDay: D): [start: D, end: D] {
   const deltaStart =
     this.dateAdapter.getFirstDayOfWeek() -
     this.dateAdapter.getDayOfWeek(forDay);
   const start = this.dateAdapter.addCalendarDays(forDay, deltaStart);
   const end = this.dateAdapter.addCalendarDays(start, 6);
   return [start, end];
 }

 private get Today(): D {
   const Today = this.dateAdapter.getValidDateOrNull(new Date());
   if (Today === null) {
     throw new Error('date creation failed');
   }
   return Today;
 }

 onReset(){
  this.picker.select(null as unknown as D);
  this.picker.select(null as unknown as D); 
  this.picker.close(); 
 }
}
