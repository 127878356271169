import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { MaterialModule } from "../shared/material/material.module";
import { AccountService } from "./services/account.service";
import { ApiService } from "./services/api.service";
import { LocalStorageService } from "./services/local-storage.service";
import { AccessControlService } from "./services/access-control.service";
import { AuthGuard } from "./guards/auth.guard";
import { TokenInterceptor } from "./interceptor/token.interceptor";
import { GuestLayoutComponent } from "./layouts/guest-layout/guest-layout.component";
import { MainDashboardLayoutComponent } from "./layouts/main-dashboard-layout/main-dashboard-layout.component";
import { HeaderComponent } from "./components/header/header.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";

@NgModule({
  declarations: [
    GuestLayoutComponent,
    MainDashboardLayoutComponent,
    HeaderComponent,
    SidebarComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MaterialModule,
  ],
  
  providers: [
    AccountService,
    ApiService,
    LocalStorageService,
    AccessControlService,
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}