import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'no-data-found',
  templateUrl: './no-data-found.component.html',
  styleUrls: ['./no-data-found.component.scss']
})
export class NoDataFoundComponent implements OnInit {
  @Input() height: string = '100%'; // Height of the component container
  @Input() width: string = '100%'; // Width of the component container
  @Input() title: string = 'No results found'; // Title text to display when no data is found
  @Input() showActionButton: boolean = false; // Flag to determine if the action button should be shown
  @Input() actionButtonText: string = 'Clear filters'; // Text for the action button
  @Input() subTitle: string = 'No results match the filter criteria. Remove filter or clear all filters to show results.'; // Subtitle text providing additional information
  @Input() iconClass: string = 'ri-search-line'; // CSS class for the icon to be displayed

  @Output() onActionEvent = new EventEmitter<void>(); // EventEmitter to emit events when the action button is clicked

  constructor() { }

  ngOnInit(): void { }

  /**
   * Handles the click event on the action button.
   * Emits the onActionEvent to notify parent components of the action.
   */
  handleClearFilters(): void {
    this.onActionEvent.emit();
  }
}