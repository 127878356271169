import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {
  @Input() totalItems: number = 0; // Total number of items
  @Input() itemsPerPage: number = 10; // Number of items per page
  @Input() currentPage: number = 0; // Current page number (0-based index)

  @Output() pageChange = new EventEmitter<number>(); // Event emitted when the page changes

  totalPages: number = 0; // Total number of pages
  pages: number[] = []; // Array of page numbers to display
  startIndex: number = 0; // Start index of items on the current page
  endIndex: number = 0; // End index of items on the current page
  maxPagesToShow: number = 5; // Maximum number of pages to show in the pagination

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['totalItems'] || changes['itemsPerPage'] || changes['currentPage']) {
      this.calculatePagination(); // Recalculate pagination details
    }
  }

  /**
   * Calculates the pagination details, including total pages and page range to display.
   * Updates the pages array, startIndex, and endIndex based on current pagination state.
   */
  calculatePagination(): void {
    this.totalPages = Math.ceil(this.totalItems / this.itemsPerPage);

    // Ensure currentPage is within valid bounds
    if (this.currentPage >= this.totalPages && this.totalPages > 0) {
      this.currentPage = this.totalPages - 1; // Adjust currentPage if it exceeds totalPages
    } else if (this.totalPages === 0) {
      this.currentPage = 0; // Reset currentPage if there are no pages
    }

    // Calculate the start and end page numbers to display
    const halfMaxPages = Math.floor(this.maxPagesToShow / 2);
    let startPage = Math.max(1, this.currentPage - halfMaxPages + 1);
    let endPage = Math.min(this.totalPages, this.currentPage + halfMaxPages + 1);

    // Adjust startPage and endPage if there are fewer pages than maxPagesToShow
    if (endPage - startPage + 1 < this.maxPagesToShow) {
      if (startPage === 1) {
        endPage = Math.min(this.maxPagesToShow, this.totalPages);
      } else if (endPage === this.totalPages) {
        startPage = Math.max(1, this.totalPages - this.maxPagesToShow + 1);
      }
    }

    // Update pages array with the new range of page numbers
    this.pages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

    // Calculate the index range of items on the current page
    if (this.totalItems === 0) {
      this.startIndex = 0;
      this.endIndex = 0; // No items to show
    } else {
      this.startIndex = this.currentPage * this.itemsPerPage + 1;
      this.endIndex = Math.min((this.currentPage + 1) * this.itemsPerPage, this.totalItems);
    }
  }

  /**
   * Changes the current page and emits the pageChange event.
   * @param page The page number to navigate to (1-based index)
   */
  changePage(page: number): void {
    if (page < 1 || page > this.totalPages || page === this.currentPage + 1) return;

    this.currentPage = page - 1;
    this.pageChange.emit(this.currentPage);
    this.calculatePagination(); // Recalculate pagination details
  }
}