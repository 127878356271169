export const API_ENDPOINTS = {
  ACCOUNT: {
    AUTH: {
      LOGIN: 'account/auth/login',
      LOGOUT: 'account/auth/logout',
      DATA: 'account/auth/data'
    },
    MODULE_ACCESS: {
      SUB_MODULE: {
        LIST: 'account/module-access/sub-module/list',
      },
    },
    USER: {
      CHECK_MODULE_ACCESS: 'account/user/check-module-access',
    },
  },
  BOQ: {
    DRAFT_LIST: {
      URL: 'search/boqs/draft'
    },
    SEARCH_LIST: {
      URL: 'search/boqs'
    },
    DOWNLOAD_LIST: {
      URL: 'download/bulk/boq'
    },
    DOWNLOAD_PDF: {
      URL: 'download/pdf/boq'
    },
    DOWNLOAD_EXCEL: {
      URL: 'download/excel/boq'
    },
    DOWNLOAD_BULK_BOQ: {
      URL: 'boq/bulk-upload'
    },
    FILTER: {
      URL: 'filters'
    },
    CATEGORY: {
      URL: 'search/category'
    },
    BRAND: {
      URL: 'search/brand'
    },
    SEARCH_CUSTOMER: {
      URL: 'search/customer'
    },
    FETCHDETAILS: {
      URL: 'boq/fetch-details'
    },
    SEARCH_ITEMS: {
      URL: 'search/item'
    },
    ADD_ITEMS: {
      URL: 'boq/item/draft/add'
    },
    SAVE: {
      URL: 'boq/save'
    },
    DRAFT: {
      URL: 'boq/draft/save'
    },
    VIEW_DEPENDENCY_DETAILS: {
      URL: 'boq/dependency'
    },
    EDIT_LIST: {
      URL: 'search/boqs/edit'
    },
    GET_EDIT_BOQ_DETAILS: {
      URL: 'boq/fetch-details'
    },
    GET_CLONE_DETAILS: {
      URL: 'boq/clone'
    },
    DOWNLOAD_EDIT_BOQ: {
      URL: 'boq/download'
    },
    UPDATE_CUSTOMER_EMAIL_PHONE: {
      URL: 'customer/update'
    },
    DELETE_ITEM: {
      URL: 'boq/item/draft/delete'
    },
    GET_REGION: {
      URL: 'mapping/fetch/region-branch'
    },
    GET_BRANCH:{
      URL:'mapping/fetch/branch-sales'
    },
    DASHBOARD_LIST:{
      URL:'dashboard/component'
    },
    MY_PENDING:{
      URL:'search/boqs/approval/my-pending'
    },
    ALL_PENDING:{
      URL:'search/boqs/approval/all-pending'
    },
    VIEW_ALL_BOQ:{
      URL:'search/boqs/approval/view-all'
    },
  },
  CUSTOMER: {
    RETRIEVE_LIST: (page: number, itemsPerPage: number) => `customer/fetch-all?page=${page}&size=${itemsPerPage}`,
    RETRIEVE_DETAILS: (customerCode: string) => `rma/customer?customerCode=${customerCode}`,
    SUSPEND: 'rma/customer/suspend',
    REVOKE_SUSPENSION: 'rma/customer/revoke',
    RETRIEVE_INVOICES_DETAILS: 'rma/fetch-invoice',
    GENERATE_DELIVERY_CHALLAN: 'rma/generate-dc',
  }
};