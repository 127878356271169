import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentenceCase'
})
export class SentenceCasePipe implements PipeTransform {

  /**
   * Transforms the input string to sentence case.
   *
   * @param value The string to be transformed.
   * @returns The transformed string in sentence case.
   *
   * The method first converts the entire string to lowercase to ensure uniformity. Then, it uses a regular expression to capitalize
   * the first letter of each sentence. The regular expression used is:
   *
   * `/(\.\s*|^)([a-z])/g`
   *
   * - `(\.\s*|^)` - Matches the beginning of the string (`^`) or a period followed by any amount of whitespace (`\.\s*`).
   * - `([a-z])` - Captures the first lowercase letter following the period or at the start of the string.
   * - `g` - Global flag to apply the replacement to all matches in the string.
   *
   * The replacement function then capitalizes this matched letter.
   */
  transform(value: string): string {
    if (!value) return value;

    return value
      .toLowerCase()
      .replace(/(?:^|\.\s*)([a-z])/g, match => match.toUpperCase());
  }
}