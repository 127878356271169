import { Injectable } from '@angular/core';
import { HttpStatusCode } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { AccessControlService } from './access-control.service';
import { API_ENDPOINTS } from '../constants/api-endpoints.constants';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(
    private apiService: ApiService,
    private accessControlService: AccessControlService
  ) { }

  /**
   * Sends login credentials to the authentication service and retrieves a token if valid.
   * @param credentials - The login data (e.g., username and password).
   * @returns Observable with the authentication response.
   */
  login(credentials: any): Observable<any> {
    const url = `${environment.microservices.digimroAuthBaseUrl}${API_ENDPOINTS.ACCOUNT.AUTH.LOGIN}`;
    return this.apiService.post(url, credentials);
  }

  /**
   * Retrieves the authenticated user's data from the service.
   * @returns Observable with the user authentication data.
   */
  retrieveAuthData(): Observable<any> {
    const url = `${environment.microservices.digimroAuthBaseUrl}${API_ENDPOINTS.ACCOUNT.AUTH.DATA}`;
    return this.apiService.get(url);
  }

  /**
   * Retrieves the user's access modules and checks if the response is OK.
   * @returns Observable<boolean> indicating whether the access modules were successfully retrieved.
   */
  retrieveUserAccessModules(): Observable<boolean> {
    return this.accessControlService.retrieveUserAccessModules().pipe(
      map((response: any) => response.status === HttpStatusCode.Ok),
      catchError(() => of(false))
    );
  }

  /**
   * Logs the user out by making a request to the logout endpoint.
   * @returns Observable with the logout response.
   */
  logout(): Observable<any> {
    const url = `${environment.microservices.accountServiceBaseUrl}${API_ENDPOINTS.ACCOUNT.AUTH.LOGOUT}`;
    return this.apiService.get(url);
  }
}