import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { HttpStatusCode } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AccountService } from '../services/account.service';
import { AccessControlService } from '../services/access-control.service';
import { LocalStorageService } from '../services/local-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { APP_ROUTES } from '../constants/app-routes.constants';
import { MESSAGE } from '../constants/message.constants';
import { Module } from 'src/app/shared/enums/modules.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private accessControlService: AccessControlService,
    private accountService: AccountService,
    private localStorageService: LocalStorageService,
    private notificationService: NotificationService,
    private router: Router,
  ) { }

  /**
   * Determines if the user can activate the route based on authentication and module access.
   * @param route - The route that is being activated.
   * @returns An Observable of a boolean indicating if the route can be activated.
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const moduleName = route.data['module'] as Module; // Extract module name from route data

    return this.isAuthenticated().pipe(
      switchMap(isAuthenticated => {
        if (!isAuthenticated) {
          this.handleUnauthorizedAccess();
          return of(false);
        }

        if (!moduleName) {
          console.error('No module name provided');
          return of(false);
        }

        return this.accessControlService.hasModuleAccess(moduleName).pipe(
          map(hasAccess => {
            if (hasAccess) {
              this.accessControlService.retrieveUserAccessModules();
              return true;
            } else {
              this.handleAccessDenied();
              return false;
            }
          }),
          catchError(() => {
            this.handleAccessDenied();
            return of(false);
          })
        );
      }),
      catchError(() => {
        this.handleUnauthorizedAccess();
        return of(false);
      })
    );
  }

  /**
   * Checks if the user is authenticated.
   * @returns An Observable of a boolean indicating if the user is authenticated.
   */
  private isAuthenticated(): Observable<boolean> {
    return this.accountService.retrieveAuthData().pipe(
      map(response => {
        const isAuthenticated = response.code === HttpStatusCode.Ok;
        if (isAuthenticated) {
          const userDetails = this.localStorageService.getItem('userDetails');
          userDetails.designationType = response.data.designationType;
          this.localStorageService.setItem('userDetails', userDetails);
        }
        return isAuthenticated;  // Return true if response code is OK
      }),
      catchError(() => of(false)) // Return false in case of an error
    );
  }

  /**
   * Handles the scenario where the user is not authenticated.
   */
  private handleUnauthorizedAccess() {
    console.error('User is not authenticated'); // Log error for unauthorized access
    this.notificationService.showError(MESSAGE.ERROR.UNAUTHORIZED_USER_ERROR); // Show error notification
    this.clearLocalStorageAndRedirect(); // Clear storage and redirect to login
  }

  /**
   * Handles the scenario where the user does not have access to the specified module.
   */
  private handleAccessDenied() {
    console.error('Access denied'); // Log error for access denial
    this.notificationService.showError(MESSAGE.ERROR.MODULE_ACCESS_DENIED); // Show error notification
    this.clearLocalStorageAndRedirect(); // Clear storage and redirect to login
  }

  /**
   * Clears local storage and redirects the user to the login page.
   */
  private clearLocalStorageAndRedirect() {
    this.localStorageService.clear(); // Clear all local storage
    this.router.navigateByUrl(APP_ROUTES.AUTH.LOGIN); // Redirect to login page
  }
}